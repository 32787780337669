<template>
  <el-popover
    popper-class="translate-content-popper"
    placement="bottom"
    width="235"
    :visible-arrow="false"
    v-model="visible"
    @hide="handleHidePopover"
  >
    <div class="translate-content" v-if="visible" v-loading="loadingTranslate">
      <template v-if="wordContent.sound">
        <div class="translate-top">
          <span class="word">{{ wordContent.word }}</span>
          <i
            class="f-icon icon-trumpet"
            :class="{ active: palyTranslateAudio }"
            @click="palyTranslate(false)"
          />
          <audio :src="wordContent.sound[0].soundUrl" ref="audio" />
        </div>
        <div class="translate-center">
          <div class="soundmark">
            {{ wordContent.sound[0].soundmark }}
          </div>
          <div v-if="wordContent.translate">
            <span v-for="(t, tindex) of wordContent.translate" :key="tindex">
              {{ t }}
            </span>
          </div>
          <div v-else>{{ i18n.Word.NoTranslation }}</div>
        </div>
        <div class="translate-bottom">
          <ul class="type-list" v-if="showTypeList">
            <li v-for="(item, index) of wordTypelist" :key="index">
              <el-checkbox v-model="item.select" class="word-checkbox">{{
                item.name
              }}</el-checkbox>
              <span class="num">{{ item.word_cnt }}</span>
            </li>
            <template v-if="wordTypelist.length < 6">
              <div class="add-type-input" v-if="showAddType">
                <el-input
                  maxlength="8"
                  size="mini"
                  :placeholder="i18n.Word.Uptowords"
                  class="edit-input"
                  v-model="typeName"
                />
                <div class="edit-btn" @click="handleAddType">
                  {{ i18n.Word.complete }}
                </div>
              </div>
              <div class="add-type" @click="changeAddType" v-else>
                <span>+</span>{{ i18n.Word.AddGroup }}
              </div>
            </template>

            <div class="btns">
              <el-button type="text" @click="showTypeList = false">{{
                i18n.Word.cancel
              }}</el-button>
              <el-button class="save-btn" @click="saveWordBook">{{
                i18n.Word.determine
              }}</el-button>
            </div>
          </ul>
          <div v-else class="add-btn" @click="fetchUserWordType">
            <i class="f-icon icon-Plus" />
            <span>{{ i18n.Word.Addto }}</span>
          </div>
        </div>
      </template>
    </div>

    <span
      :class="['word-text', styleType, visible ? 'active' : '']"
      slot="reference"
      @click="hanldeTranslate"
      ref="content"
    >
      <slot></slot>
    </span>
  </el-popover>
</template>

<script>
import * as api from '@/api/api'

export default {
  props: {
    word: {
      type: String,
      default: ''
    },
    styleType: {
      type: String,
      default: 'bg'
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  data() {
    return {
      visible: false,
      wordContent: {},
      loadingTranslate: false,
      palyTranslateAudio: false,
      wordTypelist: [],
      showTypeList: false,
      typeName: '',
      showAddType: false
    }
  },
  methods: {
    hanldeTranslate() {
      const word = this.word.match(/[A-Za-z.\'\-]*[A-Za-z]/)[0]
      if (word && !/^[a-zA-Z.\'\-]+$/i.test(word)) {
        this.visible = false
        return
      }

      this.loadingTranslate = true
      api
        .apiWebTranslate({ contents: word })
        .then((res) => {
          this.wordContent = {
            ...this.wordContent,
            ...res.data
          }
          this.palyTranslate(true)
        })
        .finally(() => {
          this.loadingTranslate = false
        })
    },
    handleHidePopover() {
      this.palyTranslateAudio = false
      this.visible = false
    },
    palyTranslate(auto) {
      this.$nextTick(() => {
        if (this.$refs.audio) {
          this.$refs.audio.play()
          if (!auto) {
            this.palyTranslateAudio = true
            setTimeout(() => {
              this.palyTranslateAudio = false
            }, 1000)
          }
        }
      })
    },
    fetchUserWordType() {
      api.apiWordTypeList().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.wordTypelist = res.data.map((item) => {
          const oldItem =
            this.wordTypelist.find((old) => old.id === item.id) || {}
          return {
            ...item,
            select: !!oldItem.select
          }
        })
        this.showTypeList = true
      })
    },
    saveWordBook() {
      api
        .apiWordAdd({
          cate_list: this.wordTypelist
            .filter((item) => item.select)
            .map((item) => item.id)
            .join(','),
          name: this.word
        })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.fetchUserWordType()
          this.handleHidePopover()

          this.$message.success('成功添加到词库')
        })
    },
    changeAddType() {
      this.showAddType = !this.showAddType
    },
    handleAddType() {
      api.apiWordTypeAdd({ name: this.typeName }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.typeName = ''
        this.fetchUserWordType()
        this.changeAddType()
      })
    }
  }
}
</script>

<style lang="scss">
.translate-content-popper {
  .translate-content {
    width: 235px;
    min-height: 158px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #1f1f40;
    cursor: auto;
    .translate-top {
      position: relative;
      margin-bottom: 8px;
    }
    .soundmark {
      margin-bottom: 7px;
    }
    .translate-center {
      min-height: 87px;
      overflow: auto;
      padding: 9px 9px 9px 0;
      border-top: 1px solid #ebebf2;
      border-bottom: 1px solid #ebebf2;
    }
    .word-title {
      position: absolute;
      right: 0;
      color: #3b86ff;
    }
    .icon-trumpet {
      margin-left: 7px;
      font-size: 12px;
      color: #3b86ff;
      cursor: pointer;
      &:hover {
        color: #ff1a2e;
      }
      &.active {
        color: #ff1a2e;
      }
    }
    .add-btn {
      margin-top: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3b86ff;
      cursor: pointer;
      font-size: 14px;
      .f-icon {
        font-size: 12px;
        margin-right: 4px;
        color: #3b86ff;
      }
    }
    .added-btn {
      margin-top: 11px;
      color: #a4afb7;
      text-align: center;
    }
    .type-list {
      margin-top: 10px;
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .num {
          color: #bfbfbf;
          font-size: 14px;
        }
        .word-checkbox {
          .el-checkbox__inner {
            width: 14px;
            height: 14px;
          }
          &:hover {
            .el-checkbox__inner {
              border-color: #3b86ff;
            }
            .el-checkbox__label {
              color: #3b86ff;
            }
          }
          .el-checkbox__input {
            margin-right: 5px;
            &.is-checked {
              .el-checkbox__inner {
                &::after {
                  width: 3px;
                  border-width: 1px;
                }
              }
            }
          }
        }
      }
    }
    .add-type {
      padding: 0 10px;
      font-size: 12px;
      color: #9c9c9c;
      height: 28px;
      line-height: 28px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        border-color: #3b86ff;
      }
      span {
        margin-right: 4px;
      }
    }
    .add-type-input {
      position: relative;
      .el-input--mini .el-input__inner {
        height: 30px;
        line-height: 30px;
        border-radius: 3px;
      }
      .el-input__inner {
        border-color: #3b86ff;
        padding-left: 9px;
      }
      .edit-btn {
        position: absolute;
        right: 1px;
        top: 1px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        width: 60px;
        font-size: 14px;
        color: #3b86ff;
        background: #c9e2ff;
        border-radius: 3px;
        border-left: 1px solid #3b86ff;
        cursor: pointer;
      }
    }

    .btns {
      margin-top: 14px;
      padding: 14px 0;
      border-top: 1px solid #dcdcdc;
      display: flex;
      justify-content: flex-end;
      .el-button {
        width: 64px;
        height: 22px;
        font-size: 12px;
        line-height: 1;
        padding: 0;
      }
      .save-btn {
        background-color: #3b86ff;
        border-color: #3b86ff;
        color: #fff;
      }
    }
  }
}
.word-text {
  cursor: pointer;
  font-size: 18px;
  &.bg {
    &:hover {
      background: #c1e6fe;
    }
    &.active {
      background: #c1e6fe;
    }
  }
  &.color {
    &:hover {
      color: #3b86ff;
    }
    &.active {
      color: #3b86ff;
    }
  }
}
</style>
